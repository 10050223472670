<template lang="pug">
  .row.ed-grid.m-grid-16
    .m-cols-1.center
      .cell {{ patient.id }}
    .m-cols-1.center
      .cell(
        v-if="patient.Profile"
      )
        CircleCheckIcon(
          v-if="patient.Profile.StateProgram.PatientState.id === 1"
          color="orange"
          :size="30"
        )
        CircleCheckIcon(
          v-if="patient.Profile.StateProgram.PatientState.id === 2"
          color="green"
          :size="30"
        )
        img(
          v-if="patient.Profile.StateProgram.PatientState.id === 3"
          src="@/assets/images/band.svg"
          style="width:30px"
        )
        img(
          v-if="patient.Profile.StateProgram.PatientState.id === 4"
          src="@/assets/images/retired.png"
          style="width:30px"
        )
        img(
          v-if="patient.Profile.StateProgram.PatientState.id === 5"
          src="@/assets/images/abandonment.svg"
          style="width:30px"
        )
      .cell(
        v-else
      )
        img(
          src="@/assets/images/cross.svg"
          style="width:30px"
        )
    .m-cols-1.center
      .cell {{patient.firstname}}
    .m-cols-1.center
      .cell {{patient.lastname}}
    .m-cols-1.center
      .cell {{patient.gender}}
    .m-cols-1.center
      .cell {{ age }}
    .m-cols-1.center
      .cell {{patient.birthday}}
    .m-cols-1.center
      .cell {{patient.dni}}
    .m-cols-1.center
      .cell {{patient.phone}}
    .m-cols-1.center
      .cell.pointer( @click="copyToClickboard(patient.email)" )
        EmailIcon( :size="25" )
    .m-cols-1.center
      .cell.pointer( @click="copyToClickboard(patient.email2)" )
        EmailIcon( :size="25" )
    .m-cols-1.center
      .cell {{ patient.ocupation || 'sin ocupacion' }}
    .m-cols-1.center
      .cell {{ patient.startWork || '----'}}
    .m-cols-1.center
      .cell {{ patient.date_start || '----'}}
    .m-cols-1.center
      .cell {{ patient.category || 'Sin categoria' }}
    .m-cols-1.center
      .cell {{ patient.date_end || '----' }}
</template>

<script>
import { CircleCheckIcon, EmailIcon } from '@/components/01-atomo/Icons'

export default {
  components: { CircleCheckIcon, EmailIcon },
  props: {
    patient: {
      required: true,
      type: Object
    }
  },
  computed: {
    ocupation () {
      const ocupations = {
        0: 'Ama de casa con trabajo ocasional',
        1: 'Trabajo fijo',
        2: 'Trabajo a tiempo parcial',
        3: 'Trabajo indepediente',
        4: 'Ama de casa',
        5: 'Sin Empleo'
      }
      if (this.patient.ocupation) return ocupations[this.patient.ocupation.toString()]
      return 'sin ocupacion'
    },
    age () {
      const nowYear = new Date().getFullYear()
      const birthdayYear = this.patient.birthday.split('-')[0]
      return nowYear - birthdayYear
    },
    iconStatus () {
      const icons = {
        notRegistered: '@/assets/images/error_circle.png',
        registered: '@/assets/images/check_yellow_circle.png',
        inProcess: '@/assets/images/check_green_circle.png',
        culminated: '@/assets/images/flag.png'
      }
      console.log(icons[this.patient.status])
      return icons[this.patient.status]
    }
  },
  methods: {
    async copyToClickboard (text) {
      await navigator.clipboard.writeText(text)
      this.TOAST('El correo se copio al portapapeles', 'success')
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
