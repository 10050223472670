<template lang="pug">
  .c-table.c-session-table
    .c-table__head.ed-grid.m-grid-16
      .m-cols-1.center Codigo
      .m-cols-1.center Estado
      .m-cols-1.center Nombres
      .m-cols-1.center Apellidos
      .m-cols-1.center Género
      .m-cols-1.center Edad
      .m-cols-1.center F. de nac.
      .m-cols-1.center DNI
      .m-cols-1.center Celular
      .m-cols-1.center Correo 1
      .m-cols-1.center Correo 2
      .m-cols-1.center Puesto laboral
      .m-cols-1.center Fecha de ing.
      .m-cols-1.center Inicio de patrocinio.
      .m-cols-1.center Categoria.
      .m-cols-1.center Fin de patrocinio.
    .c-table__body
      PatientTableRow(
        v-for="(patient, key) in patients"
        :key="key"
        :patient="patient"
      )
</template>

<script>
import PatientTableRow from '@/components/02-molecule/patientTable/row'

export default {
  components: { PatientTableRow },
  props: {
    patients: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
