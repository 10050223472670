<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 94 94"
  >
    <g id="Grupo_5" data-name="Grupo 5" transform="translate(-857.473 -494)">
      <path
        id="Trazado_11"
        data-name="Trazado 11"
        d="M28.967-142.331a5,5,0,0,0-7.042.646l-24.81,29.822-11.84-11.644a5,5,0,0,0-7.071.059,5,5,0,0,0,.059,7.071l15.715,15.454,3.5-3.565h0l-3.5,3.565,3.874,3.811,3.475-4.178,28.286-34A5,5,0,0,0,28.967-142.331Z"
        transform="translate(900.704 662.488)"
        :fill="color" />
      <path
        id="Trazado_12"
        data-name="Trazado 12"
        d="M3.769-168.488a47.053,47.053,0,0,0-47,47,47.053,47.053,0,0,0,47,47,47.053,47.053,0,0,0,47-47A47.053,47.053,0,0,0,3.769-168.488Zm0,88a41.047,41.047,0,0,1-41-41,41.047,41.047,0,0,1,41-41,41.047,41.047,0,0,1,41,41A41.047,41.047,0,0,1,3.769-80.488Z"
        transform="translate(900.704 662.488)"
        :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      required: true,
      type: Number
    },
    color: {
      required: false,
      type: String
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
