<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.5" cy="8.5" r="8.5" fill="#006AB6"/>
    <path d="M8.61119 4.60415V12.618M12.6181 8.61108H4.60425H12.6181Z" stroke="white" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>
</style>
