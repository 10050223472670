<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5264 6.31641C24.7168 6.16504 25 6.30664 25 6.5459V16.5312C25 17.8252 23.9502 18.875 22.6562 18.875H2.34375C1.0498 18.875 0 17.8252 0 16.5312V6.55078C0 6.30664 0.27832 6.16992 0.473633 6.32129C1.56738 7.1709 3.01758 8.25 7.99805 11.8682C9.02832 12.6201 10.7666 14.2021 12.5 14.1924C14.2432 14.207 16.0156 12.5908 17.0068 11.8682C21.9873 8.25 23.4326 7.16602 24.5264 6.31641ZM12.5 12.625C13.6328 12.6445 15.2637 11.1992 16.084 10.6035C22.5635 5.90137 23.0566 5.49121 24.5508 4.31934C24.834 4.09961 25 3.75781 25 3.39648V2.46875C25 1.1748 23.9502 0.125 22.6562 0.125H2.34375C1.0498 0.125 0 1.1748 0 2.46875V3.39648C0 3.75781 0.166016 4.09473 0.449219 4.31934C1.94336 5.48633 2.43652 5.90137 8.91602 10.6035C9.73633 11.1992 11.3672 12.6445 12.5 12.625Z"
      fill="#006AB6" />
  </svg>
</template>

<script>
export default {
  data () {
    return {
      style: []
    }
  },
  props: {
    size: {
      required: true,
      type: Number
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
