<template lang="pug">
  .c-legend
    slot
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>
</style>
