<template lang="pug">
  .c-sessions
    .ed-grid.m-grid-6
      .m-cols-3(
        style="margin-top:-3rem"
      )
        .c-history__options
          .c-history__filters
          .e-btn.e-btn--rounded.e-btn--small.e-btn--white.e-btn--secondary.e-btn--icon
            i.icon-rango_fechas_ico
          .e-btn.e-btn--rounded.e-btn--secondary.e-btn--small.e-btn--white(
            @click="openCalendar"
          ) Fecha de ingreso
          DateRangePicker(
            v-if="isOpenCalendar"
            ref="picker"
            opens="inline"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
            minDate="01-01-2021"
            singleDatePicker="range"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="true"
            :autoApply="true"
            :ranges="false"
            v-model="dateRange"
            :linkedCalendars="true"
            v-on:select="changeCurrentPicker"
            style="position:absolute;z-index:99999;left:80px;top:60px"
          )
          .c-search(
            style="margin-top:1.5rem"
            v-click-outside="hide"
            @click="toggleSearch = true"
            :class="toggleSearch ? 'active': ''"
          )
            input(
              v-model="data.search"
              name="search"
              type="text"
              placeholder="Buscar"
              @change="search"
            )
            i.icon-buscar_ico
          DownloadExcel.e-btn.e-btn--secondary(
            :data="json_data"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="pacientes.xls"
            style="display:flex; align-items:center;padding:0 20px;height: 33px"
          )
            img(
              src="@/assets/images/excel.svg"
              style="margin:0 10px 0 0"
            )
            span Descargar
          //- SelectInput.white(
          //-   v-model="data.category"
          //-   :elements="filters.categories"
          //-   :placeholder="currentFilters.category"
          //-   @input="search"
          //- )
          SelectInput.white(
            v-model="data.rankAge"
            :elements="filters.rankAges"
            @input="search"
            :placeholder="currentFilters.rankAge"
          )
          SelectInput.white(
            v-model="data.gender"
            :elements="filters.genders"
            :placeholder="filters.genders[0]"
            @input="search"
          )
          SelectInput.white(
            :elements="G_PATIENT_STATES"
            :placeholder="G_PATIENT_STATES[0]"
            @input="changeCurrentSelect($event, 'fetchFilters', 'stateID');search()"
          )
          .e-btn.e-btn--secondary.mt-5(
            @click="$router.push({ name: 'SessionReschedulePage' })"
          ) Ver sesiones reagendadas
      .m-cols-3
        LegendTable.c-history__legend(
          style="margin:0 20px;max-width: max-content;display: flex;align-items:center"
        )
          ul.primary(style="margin:0")
            li
              img(
                style="width:25px;margin: 0 5px;"
                src="@/assets/images/cross.svg"
              )
              span(style="white-space: nowrap") Sin registrado
            li
              img(
                style="width:25px;margin: 0 5px;"
                src="@/assets/images/checkc.svg"
              )
              span(style="white-space: nowrap") Registrado
            li
              img(
                style="width:25px;margin: 0 5px;"
                src="@/assets/images/check.svg"
              )
              span(style="white-space: nowrap") En proceso
            li
              img(
                style="width:25px;margin: 0 5px;"
                src="@/assets/images/band.svg"
              )
              span(style="white-space: nowrap") Culminado
        div(
          style="display:flex;align-items:center;padding: 10px;justify-content:flex-end;"
        )
          h2.total
            span {{ S_PROFILE_CATALOGS.length }}&nbsp;
            | de {{ S_PROFILE_CATALOGS_COUNT }} Pacientes
      .m-cols-6
        PatientTable(
          :patients="S_PROFILE_CATALOGS"
        )
      .m-cols-6.paginator
        .nav-text Mostrando {{ S_PROFILE_CATALOGS.length }} de {{ S_PROFILE_CATALOGS_COUNT }} elementos
        img.nav-icon(
          src="@/assets/fonts/icons/arrow_left.svg"
          @click="previousPage"
        )
        .nav-text.focus {{ S_CURRENT_PAGE + 1 }}
        .nav-text de {{ S_MAX_PAGES }}
        img.nav-icon(
          src="@/assets/fonts/icons/arrow_rigth.svg"
          @click="nextPage"
        )
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import PatientTable from '@/components/03-organism/patientsTable/default'
import LegendTable from '@/components/01-atomo/legendTable/default'
import SelectInput from '@/components/02-molecule/formElements/select'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import jwtDecode from 'jwt-decode'

export default {
  components: { PatientTable, LegendTable, SelectInput, DateRangePicker },
  data: () => ({
    data: {
      category: {},
      search: null,
      rankAge: null,
      status: {},
      gender: {}
    },
    tableActive: 'patients', // patients - sessionsReschedule
    fetchFilters: {
      stateID: null
    },
    toggleSearch: false,
    filters: {
      categories: [
        {
          name: 'Categoria',
          value: null
        },
        {
          name: 'Categoria 1',
          value: 1
        },
        {
          name: 'Categoria 2',
          value: 2
        },
        {
          name: 'Categoria 3',
          value: 3
        }
      ],
      genders: [
        {
          name: 'Genero',
          value: null
        },
        {
          name: 'Femenino',
          value: 'F'
        },
        {
          name: 'Masculino',
          value: 'M'
        }
      ],
      rankAges: [
        {
          name: 'Rango de edad',
          value: null
        },
        {
          name: '18-25',
          value: '18-25'
        },
        {
          name: '25-35',
          value: '25-35'
        },
        {
          name: '35-60',
          value: '35-60'
        }
      ],
      status: [
        {
          name: 'Estado',
          value: null
        },
        {
          name: 'No registrado',
          value: 'notRegistered'
        },
        {
          name: 'Registrado',
          value: 'registered'
        },
        {
          name: 'En proceso',
          value: 'inProcess'
        },
        {
          name: 'Culminado',
          value: 'culminated'
        }
      ]
    },
    currentFilters: {
      category: {
        name: 'Categoria',
        value: null
      },
      rankAge: {
        name: 'Rango de Edad',
        value: null
      }
    },
    isOpenCalendar: false,
    dateRange: {
      startDate: '',
      endDate: ''
    },
    json_fields: {
      Codigo: 'id',
      Nombres: 'firstname',
      Apellidos: 'lastname',
      Genero: 'gender',
      Edad: 'age',
      'Fecha de nacimiento': 'birthday',
      Dni: 'dni',
      Celular: 'phone',
      Correo: 'email',
      'Puesto laboral': 'ocupation',
      'Fecha de ingreso': 'startWork',
      'Inicio de patrocinio': 'date_start',
      Categoria: 'category',
      'Fin de patrocinio': 'date_end',
      Estado: 'status'
    },
    json_data: [],
    json_meta: [
      [
        {
          key: 'charset',
          value: 'utf-8'
        }
      ]
    ],
    limitRegisters: 20
  }),
  computed: {
    ...mapState({
      S_PROFILE_CATALOGS_COUNT: state => state.Patients.S_PROFILE_CATALOGS_COUNT,
      S_PROFILE_CATALOGS: state => state.Patients.S_PROFILE_CATALOGS,
      S_MAX_PAGES: state => state.Patients.S_MAX_PAGES,
      S_CURRENT_PAGE: state => state.Patients.S_CURRENT_PAGE
    }),
    ...mapGetters({
      G_PATIENT_STATES: 'Patients/G_PATIENT_STATES'
    })
  },
  async created () {
    await this.getAllProfileCatalogs()
    await this.A_GET_PATIENT_STATE({})
    await this.downloadExcel()
    this.assignStartDateToCalendar()
  },
  methods: {
    ...mapActions({
      A_GET_PROFILE_CATALOGS: 'Patients/A_GET_PROFILE_CATALOGS',
      A_SET_CURRENT_PAGE: 'Patients/A_SET_CURRENT_PAGE',
      A_ONLY_GET_ALL_PROFILE_CATALOGS: 'Patients/A_ONLY_GET_ALL_PROFILE_CATALOGS',
      A_GET_PATIENT_STATE: 'Patients/A_GET_PATIENT_STATE'
    }),
    changeCurrentSelect (event, type, key) {
      this[type][key] = event.value
    },
    changeCurrentTable (name) {
      this.tableActive = name
    },
    async getAllProfileCatalogs () {
      const idInstitution = jwtDecode(this.$cookies.get('AUTH_TOKEN')).user.idInstitution
      await this.A_GET_PROFILE_CATALOGS({
        idInstitution,
        limit: this.limitRegisters,
        offset: 0
      })
    },
    changeCurrentRankAges (e) {
      this.currentFilters.rankAge = e
    },
    hide () {
      this.toggleSearch = false
    },
    previousPage () {
      if (this.S_CURRENT_PAGE > 0) {
        this.A_SET_CURRENT_PAGE(this.S_CURRENT_PAGE - 1)
        this.search()
      }
    },
    nextPage () {
      if (this.S_CURRENT_PAGE < (this.S_MAX_PAGES - 1)) {
        this.A_SET_CURRENT_PAGE(this.S_CURRENT_PAGE + 1)
        this.search()
      }
    },
    async search () {
      try {
        const { category, search, gender, status } = this.data
        const { startDate, endDate } = this.currentFilters
        const { stateID } = this.fetchFilters
        let rankAge = [null, null]
        if (this.data.rankAge && this.data.rankAge.value) {
          rankAge = this.data.rankAge.value.split('-')
        }
        const params = {
          age_range_min: rankAge[0],
          age_range_max: rankAge[1],
          limit: this.limitRegisters,
          offset: this.S_CURRENT_PAGE,
          stateID
        }
        params.search = search
        params.startDate = startDate
        params.endDate = endDate
        if (gender.value !== null) {
          params.gender = gender.value
        }
        if (category.value !== null && category.value !== undefined) {
          params.category = category.value
        }
        if (status.value !== null && status.value !== undefined) {
          console.log(status.value)
          params.status = status.value
        }
        params.idInstitution = jwtDecode(this.$cookies.get('AUTH_TOKEN')).user.idInstitution
        await this.A_GET_PROFILE_CATALOGS(params)
        console.log('Pacientes : ', this.S_PROFILE_CATALOGS)
      } catch (error) {
        console.log('Something went error', error)
      }
    },
    openCalendar () {
      this.isOpenCalendar = !this.isOpenCalendar
    },
    changeCurrentPicker () {
      this.currentFilters.startDate = this.$refs.picker.$data.start
      this.currentFilters.endDate = this.$refs.picker.$data.end
      this.search()
    },
    getAge (birthday) {
      const nowYear = new Date().getFullYear()
      const birthdayYear = birthday.split('-')[0]
      return nowYear - birthdayYear
    },
    translateStatus (statusText) {
      const translateTexts = {
        notRegistered: 'No registrado',
        registered: 'Registrado',
        inProcess: 'En proceso',
        culminated: 'Culminado'
      }
      return translateTexts[statusText]
    },
    async downloadExcel () {
      const patients = await this.A_ONLY_GET_ALL_PROFILE_CATALOGS({
        idInstitution: jwtDecode(this.$cookies.get('AUTH_TOKEN')).user.idInstitution
      })
      patients.map(patient => {
        this.json_data.push({
          ...patient,
          age: this.getAge(patient.birthday),
          status: this.translateStatus(patient.status)
        })
      })
    },
    assignStartDateToCalendar () {
      // Agregar la fecha actual como iniciador para el Calendar Component
      var today = new Date()
      var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.dateRange.startDate = date
      this.dateRange.endDate = date
      // ------------------------------------------------------------------
    }
  }
}
</script>

<style lang="scss" scoped>
  .paginator {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    align-items: center;
    * {
      margin: 0 5px;
    }
    .nav-text {
      color: #858C90;
      padding: 3px 6px;
      &.focus {
        color: #0D3356;
        border: 2px solid #0D3356;
        border-radius: 5px;
      }
    }
    .nav-icon {
      font-size: 15px;
      cursor: pointer;
    }
  }
</style>
